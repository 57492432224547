import React from 'react';
import { Helmet } from 'react-helmet';
import { useLocation } from "@reach/router"

const Seo = ({
  descritpion,
  imgurl,
  subtitile,
  title,
  metaTitle,
  metaKeywords,
  metaDescripcin
}) => {
  const { pathname } = useLocation()
  return (
    <Helmet>
      <title>{title ? title : "UPC"}</title>
      <meta property="type" content="website" />
      <meta property="url" content={`https://cultural.upc.edu.pe${pathname}`} />
      <meta property="og:title" content={metaTitle} />
      <meta property="quote" content={`#${title}`} />
      <meta property="image" content={`https:${imgurl}`} />
      <meta property="og:locale" content="es_PE" />
      <meta property="og:description" content={metaDescripcin} />
      <meta property="og:type" content="website" />
      <meta property="og:quote" content={`#${title}`} />
      <meta property="og:image" content={`https:${imgurl}`} />
      <meta content="image/*" property="og:image:type" />
      <meta property="og:url" content={`https://cultural.upc.edu.pe${pathname}`} />
      <meta property="og:site_name" content="UPC" />
      <meta property="twitter:image" content={`https:${imgurl}`} />
      <meta property="og:image:secure_url" content={`https:${imgurl}`} />
      <meta name="description" content={metaDescripcin} />
      <meta name="keywords" content={metaKeywords} />
      <meta property="og:locale" content="es_LA" />
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:title" content={metaTitle} />
      <meta name="twitter:description" content={metaDescripcin} />
      <meta name="twitter:image" content={`https:${imgurl}`} />
    </Helmet>
  )
}

export default Seo
